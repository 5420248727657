// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "menu-items";
import { useSelector } from "react-redux";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const userData = JSON.parse(window.localStorage.getItem("User State"));
  console.log(userData);
  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography
            key={item.id}
            variant="h6"
            color="error"
            align="center"
          ></Typography>
        );
    }
  });
  const navAdminItems = menuItem.adminIems.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography
            key={item.id}
            variant="h6"
            color="error"
            align="center"
          ></Typography>
        );
    }
  });
  const navSuperAdminItems = menuItem.superAdmin.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography
            key={item.id}
            variant="h6"
            color="error"
            align="center"
          ></Typography>
        );
    }
  });
  const navStaffItems = menuItem.staff.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography
            key={item.id}
            variant="h6"
            color="error"
            align="center"
          ></Typography>
        );
    }
  });
  const navDataEntryItems = menuItem.dataEntry.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography
            key={item.id}
            variant="h6"
            color="error"
            align="center"
          ></Typography>
        );
    }
  });
  const facultyRoutes = menuItem.faculty.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography
            key={item.id}
            variant="h6"
            color="error"
            align="center"
          ></Typography>
        );
    }
  });

  return (
    <>
      {userData?.user?.role == 1
        ? navSuperAdminItems
        : userData?.user?.role == 4
        ? navItems
        : userData?.user?.role == 3
        ? facultyRoutes
        : userData?.user?.role == 2
        ? navAdminItems
        : userData?.user?.role == 7
        ? navStaffItems
        : userData?.user?.role == 5
        ? navDataEntryItems
        : facultyRoutes}
    </>
  );
};

export default MenuList;
