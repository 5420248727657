import React, { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import endpoints from '../../../routes/NetworkRoutes';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { read, utils } from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import UploadToAWS from '../../utilities/AWSUpload';

function UpdateQuestion() {
    const { examIdParams, questionIdParams, batchIdParams } = useParams();
    const navigate = useNavigate();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const userData = JSON.parse(window.localStorage.getItem('User State'));
    const [file, setFile] = useState();
    const [optionAUrl, setOptionAUrl] = useState('');
    const [optionBUrl, setOptionBUrl] = useState('');
    const [optionCUrl, setOptionCUrl] = useState('');
    const [optionDUrl, setOptionDUrl] = useState('');
    const [optionAImage, setOptionAImage] = useState('');
    const [optionBImage, setOptionBImage] = useState('');
    const [optionCImage, setOptionCImage] = useState('');
    const [optionDImage, setOptionDImage] = useState('');
    const [question, setQuestion] = useState({
        Subject: '',
        Topic: '',
        Marks: '',
        TypeI: '',
        TypeII: '',
        Targeted_Exam: '',
        DifficultyLevel: '',
        CorrectMarks: '',
        WrongMarks: '',
        QuestionType: 'Descriptive',
        OptionAType: 'Descriptive',
        OptionBType: 'Descriptive',
        OptionCType: 'Descriptive',
        OptionDType: 'Descriptive',
        ImageUrl: '',
        QuestionInEng: '',
        QuestionInHin: '',
        QuestionInGuj: '',
        OptionAInEng: '',
        OptionAInHin: '',
        OptionAInGuj: '',
        OptionAUrl: '',
        OptionBInEng: '',
        OptionBInHin: '',
        OptionBInGuj: '',
        OptionBUrl: '',
        OptionCInEng: '',
        OptionCInHin: '',
        OptionCInGuj: '',
        OptionCUrl: '',
        OptionDInEng: '',
        OptionDInHin: '',
        OptionDInGuj: '',
        OptionDUrl: ''
    });

    const [labels, setLabels] = useState();

    const makeCallToGetLabels = async () => {
        const getLabelsEndpoint = endpoints.getQuestionLabels;

        const response = await axios.get(getLabelsEndpoint, {
            headers: {
                Authorization: `Bearer ${userData.token}`
            }
        });
        return response;
    };

    const getQuestionsOnId = async () => {
        try {
            const response = await axios.get(endpoints.getQuestionsOnId + questionIdParams + '/' + examIdParams, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            console.log(response);
            setQuestion({
                Subject: response.data.data.Subject,
                Topic: response.data.data.Topic,
                TypeI: response.data.data.Type1,
                TypeII: response.data.data.Type2,
                DifficultyLevel: response.data.data.DifficultyLevel,
                ImageUrl: response.data.data?.ImageUrl,
                CorrectOption: response.data.data.CorrectOption,
                Targeted_Exam: response.data.data.TargetedExam,
                QuestionType: response.data.data?.QuestionType,
                CorrectMarks: response.data.data.CorrectMark['$numberDecimal'] - '0',
                WrongMarks: response.data.data.WrongMark['$numberDecimal'] - '0',
                QuestionInEng: response.data.data.Description.QuestionInEng,
                QuestionInHin: response.data.data.Description.QuestionInHin,
                QuestionInGuj: response.data.data.Description.QuestionInGuj,
                OptionAInEng: response.data.data.Options[0].OptionInEng,
                OptionAInHin: response.data.data.Options[0].OptionInHin,
                OptionAInGuj: response.data.data.Options[0].OptionInGuj,
                OptionBInEng: response.data.data.Options[1].OptionInEng,
                OptionBInHin: response.data.data.Options[1].OptionInHin,
                OptionBInGuj: response.data.data.Options[1].OptionInGuj,
                OptionCInEng: response.data.data.Options[2].OptionInEng,
                OptionCInHin: response.data.data.Options[2].OptionInHin,
                OptionCInGuj: response.data.data.Options[2].OptionInGuj,
                OptionDInEng: response.data.data.Options[3].OptionInEng,
                OptionDInHin: response.data.data.Options[3].OptionInHin,
                OptionDInGuj: response.data.data.Options[3].OptionInGuj,
                OptionAType: response.data.data.Options[0].OptionType,
                OptionBType: response.data.data.Options[1].OptionType,
                OptionCType: response.data.data.Options[2].OptionType,
                OptionDType: response.data.data.Options[3].OptionType,
                OptionAUrl: response.data.data.Options[0].ImageUrl,
                OptionBUrl: response.data.data.Options[1].ImageUrl,
                OptionCUrl: response.data.data.Options[2].ImageUrl,
                OptionDUrl: response.data.data.Options[3].ImageUrl,
                OptionAType: response.data.data.Options[0].OptionType,
                OptionBType: response.data.data.Options[1].OptionType,
                OptionCType: response.data.data.Options[2].OptionType,
                OptionDType: response.data.data.Options[3].OptionType
            });
            console.log(response.data.data.QuestionType === 'Image');
            if (response.data.data.QuestionType === 'Image' && response.data.data.ImageUrl.length > 0) {
                const responseUrl = await axios.get(endpoints.getPresignedUrlToViewDocuments + '?key=' + response.data.data.ImageUrl, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`
                    }
                });
                console.log(responseUrl);
                if (responseUrl.data.code == 200) {
                    console.log(responseUrl.data.data);
                    setImageUrl(responseUrl.data.data.getUrl);
                }
            }
            if (response.data.data.Options[0].OptionType === 'Image' && response.data.data.Options[0].ImageUrl.length > 0) {
                const responseUrl = await axios.get(
                    endpoints.getPresignedUrlToViewDocuments + '?key=' + response.data.data.Options[0].ImageUrl,
                    {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    }
                );
                console.log(responseUrl);
                if (responseUrl.data.code == 200) {
                    console.log(responseUrl.data.data);
                    setOptionAUrl(responseUrl.data.data.getUrl);
                }
            }
            if (response.data.data.Options[1].OptionType === 'Image' && response.data.data.Options[1].ImageUrl.length > 0) {
                const responseUrl = await axios.get(
                    endpoints.getPresignedUrlToViewDocuments + '?key=' + response.data.data.Options[1].ImageUrl,
                    {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    }
                );
                console.log(responseUrl);
                if (responseUrl.data.code == 200) {
                    console.log(responseUrl.data.data);
                    setOptionBUrl(responseUrl.data.data.getUrl);
                }
            }
            if (response.data.data.Options[2].OptionType === 'Image' && response.data.data.Options[2].ImageUrl.length > 0) {
                const responseUrl = await axios.get(
                    endpoints.getPresignedUrlToViewDocuments + '?key=' + response.data.data.Options[2].ImageUrl,
                    {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    }
                );
                console.log(responseUrl);
                if (responseUrl.data.code == 200) {
                    console.log(responseUrl.data.data);
                    setOptionCUrl(responseUrl.data.data.getUrl);
                }
            }
            if (response.data.data.Options[3].OptionType === 'Image' && response.data.data.Options[3].ImageUrl.length > 0) {
                const responseUrl = await axios.get(
                    endpoints.getPresignedUrlToViewDocuments + '?key=' + response.data.data.Options[3].ImageUrl,
                    {
                        headers: {
                            Authorization: `Bearer ${userData.token}`
                        }
                    }
                );
                console.log(responseUrl);
                if (responseUrl.data.code == 200) {
                    console.log(responseUrl.data.data);
                    setOptionDUrl(responseUrl.data.data.getUrl);
                }
            }
        } catch (error) {
            if (error.code === 'ERR_BAD_REQUEST') {
                if (error.response.data.message?.length > 0) {
                    for (let i = 0; i < error.response.data.message.length; i++) {
                        toast.error(error.response.data.message[i].msg);
                    }
                } else {
                    toast.error(error.response.data.message);
                }
            } else if (error.code === 'ERR_BAD_RESPONSE') toast.error(error.response.data.message);
            else {
                toast.error(error.message);
            }
        }
    };

    useEffect(() => {
        makeCallToGetLabels().then((response) => {
            console.log(response);
            if (response.data.code === 200) {
                setLabels(response.data.data);
            } else {
                toast.error('Labels Not Found');
            }
        });
        getQuestionsOnId();
    }, []);

    const getUrl = async (file) => {
        if (!file) {
            return '';
        }
        let url = '';
        await UploadToAWS(file)
            .then(async (response) => {
                console.log('response', response);
                if (response.status === 200) {
                    url = response.key;
                }
            })
            .catch((err) => {
                toast.error(err);
            });
        return url;
    };

    const handleQuestionEdit = async (e) => {
        e.preventDefault();
        setSubmitLoading(true);
        let details = {};
        console.log('QuestionStatus', question);
        try {
            let questionUrl = '';
            let optionAUrl = '';
            let optionBUrl = '';
            let optionCUrl = '';
            let optionDUrl = '';
            if (question.QuestionType === 'Image') {
                questionUrl = file ? await getUrl(file) : question.ImageUrl;
            }
            if (question.OptionAType === 'Image') {
                optionAUrl = optionAImage ? await getUrl(optionAImage) : question.OptionAUrl;
            }
            if (question.OptionBType === 'Image') {
                optionBUrl = optionBImage ? await getUrl(optionBImage) : question.OptionBUrl;
            }
            if (question.OptionCType === 'Image') {
                optionCUrl = optionCImage ? await getUrl(optionCImage) : question.OptionCUrl;
            }
            if (question.OptionDType === 'Image') {
                optionDUrl = optionDImage ? await getUrl(optionDImage) : question.OptionDUrl;
            }
            details = {
                ExamId: examIdParams,
                Qid: questionIdParams,
                Subject: question.Subject,
                Topic: question.Topic,
                Type1: question.TypeI,
                Type2: question.TypeII,
                DifficultyLevel: question.DifficultyLevel,
                CorrectOption: question.CorrectOption,
                TargetedExam: question.Targeted_Exam,
                QuestionType: question.QuestionType,
                ImageUrl: questionUrl,
                CorrectMarks: question.CorrectMarks,
                WrongMarks: question.WrongMarks,
                Description: {
                    QuestionInEng: question.QuestionInEng,
                    QuestionInHin: question.QuestionInHin,
                    QuestionInGuj: question.QuestionInGuj
                },
                Options: [
                    {
                        OptionType: question.OptionAType,
                        OptionInEng: question.OptionAInEng,
                        OptionInHin: question.OptionAInHin,
                        OptionInGuj: question.OptionAInGuj,
                        ImageUrl: optionAUrl
                    },
                    {
                        OptionType: question.OptionBType,
                        OptionInEng: question.OptionBInEng,
                        OptionInHin: question.OptionBInHin,
                        OptionInGuj: question.OptionBInGuj,
                        ImageUrl: optionBUrl
                    },
                    {
                        OptionType: question.OptionCType,
                        OptionInEng: question.OptionCInEng,
                        OptionInHin: question.OptionCInHin,
                        OptionInGuj: question.OptionCInGuj,
                        ImageUrl: optionCUrl
                    },
                    {
                        OptionType: question.OptionDType,
                        OptionInEng: question.OptionDInEng,
                        OptionInHin: question.OptionDInHin,
                        OptionInGuj: question.OptionDInGuj,
                        ImageUrl: optionDUrl
                    }
                ]
            };
            const response = await axios.post(endpoints.EditQuestionsOnExamId, details, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            console.log(response);
            if (response.status === 200) {
                toast.success('Question Updated Successfully!');
                navigate('/dashboard/entranceExam/showQuestions/' + examIdParams + '/');
                // setQuestion({
                //   Subject: "Entrance",
                //   Topic: "GPSC-1-2",
                //   QuestionType: "Descriptive",
                //   TypeI: "N/A",
                //   TypeII: "N/A",
                //   Targeted_Exam: "GPSC",
                //   DifficultyLevel: "N/A",
                //   CorrectMarks: "1",
                //   WrongMarks: "0",
                //   CorrectOption: "1",
                //   QuestionInEng: "",
                //   QuestionInHin: "",
                //   QuestionInGuj: "",
                //   OptionAInEng: "",
                //   OptionAInHin: "",
                //   OptionAInGuj: "",
                //   OptionBInEng: "",
                //   OptionBInHin: "",
                //   OptionBInGuj: "",
                //   OptionCInEng: "",
                //   OptionCInHin: "",
                //   OptionCInGuj: "",
                //   OptionDInEng: "",
                //   OptionDInHin: "",
                //   OptionDInGuj: "",
                // });
            } else {
                toast.error('Something went wrong!');
            }
        } catch (error) {
            console.log(error);
            if (error.code === 'ERR_BAD_REQUEST') {
                if (error.response.data.message?.length > 0) {
                    for (let i = 0; i < error.response.data.message.length; i++) {
                        toast.error(error.response.data.message[i].msg);
                    }
                } else {
                    toast.error(error.response.data.message);
                }
            } else if (error.code === 'ERR_BAD_RESPONSE') toast.error(error.response.data.message);
            else {
                toast.error(error.message);
            }
        }
        setSubmitLoading(false);
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (e.target.name === 'OptionAImage') {
            setOptionAImage(selectedFile);
            const reader = new FileReader();
            reader.onload = () => {
                setOptionAUrl(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        } else if (e.target.name === 'OptionBImage') {
            setOptionBImage(selectedFile);
            const reader = new FileReader();
            reader.onload = () => {
                setOptionBUrl(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        } else if (e.target.name === 'OptionCImage') {
            setOptionCImage(selectedFile);
            const reader = new FileReader();
            reader.onload = () => {
                setOptionCUrl(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        } else if (e.target.name === 'OptionDImage') {
            setOptionDImage(selectedFile);
            const reader = new FileReader();
            reader.onload = () => {
                setOptionDUrl(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            setFile(selectedFile);

            const reader = new FileReader();
            reader.onload = () => {
                setImageUrl(reader.result);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    return (
        <div>
            <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.js" defer></script>
            <ToastContainer></ToastContainer>
            <MainCard>
                <div className="text-center font-semibold text-2xl text-black">Edit Question For Exam</div>
                <div className="p-4">
                    <div>
                        <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5">
                            <div className="relative">
                                <label for="Subject" className="block mb-2 text-sm font-medium text-gray-700 ">
                                    Subject
                                </label>
                                <select
                                    id="Subject"
                                    value={question.Subject}
                                    required
                                    disabled={submitLoading}
                                    onChange={(e) => setQuestion({ ...question, Subject: e.target.value })}
                                    className=" border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 "
                                >
                                    {labels && labels['subject'] && labels['subject'].length > 0 ? (
                                        labels['subject'].map((value, key) => {
                                            if (value && value !== '' && value !== ' ' && value !== 'N/A') {
                                                return (
                                                    <option value={value} key={key}>
                                                        {value}
                                                    </option>
                                                );
                                            }
                                        })
                                    ) : (
                                        <option value="N/A">N/A</option>
                                    )}
                                </select>
                            </div>
                            <div className="relative">
                                <label for="Topic" className="block mb-2 text-sm font-medium text-gray-700 ">
                                    Topic
                                </label>
                                <select
                                    id="Topic"
                                    value={question.Topic}
                                    required
                                    disabled={submitLoading}
                                    onChange={(e) => setQuestion({ ...question, Topic: e.target.value })}
                                    className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                >
                                    {labels && labels['topic'] && labels['topic'].length > 0 ? (
                                        labels['topic'].map((value, key) => {
                                            if (value && value !== '' && value !== ' ' && value !== 'N/A') {
                                                return (
                                                    <option value={value} key={key}>
                                                        {value}
                                                    </option>
                                                );
                                            }
                                        })
                                    ) : (
                                        <option value="N/A">N/A</option>
                                    )}
                                </select>
                            </div>

                            <div className="relative">
                                <label for="TypeI" className="block mb-2 text-sm font-medium text-gray-700 ">
                                    Type I
                                </label>
                                <select
                                    id="TypeI"
                                    value={question.TypeI}
                                    required
                                    disabled={submitLoading}
                                    onChange={(e) => setQuestion({ ...question, TypeI: e.target.value })}
                                    className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                >
                                    {labels && labels['type1'] && labels['type1'].length > 0 ? (
                                        labels['type1'].map((value, key) => {
                                            if (value && value !== '' && value !== ' ' && value !== 'N/A') {
                                                return (
                                                    <option value={value} key={key}>
                                                        {value}
                                                    </option>
                                                );
                                            }
                                        })
                                    ) : (
                                        <option value="N/A">N/A</option>
                                    )}
                                </select>
                            </div>
                            <div className="relative">
                                <label for="TypeII" className="block mb-2 text-sm font-medium text-gray-700 ">
                                    TypeII
                                </label>
                                <select
                                    id="TypeII"
                                    value={question.TypeII}
                                    required
                                    disabled={submitLoading}
                                    onChange={(e) => setQuestion({ ...question, TypeII: e.target.value })}
                                    className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                >
                                    {labels && labels['type2'] && labels['type2'].length > 0 ? (
                                        labels['type2'].map((value, key) => {
                                            if (value && value !== '' && value !== ' ' && value !== 'N/A') {
                                                return (
                                                    <option value={value} key={key}>
                                                        {value}
                                                    </option>
                                                );
                                            }
                                        })
                                    ) : (
                                        <option value="N/A">N/A</option>
                                    )}
                                </select>
                            </div>
                            <div className="relative">
                                <label for="TargetedExam" className="block mb-2 text-sm font-medium text-gray-700 ">
                                    Targeted Exam
                                </label>
                                <select
                                    id="TargetedExam"
                                    value={question.Targeted_Exam}
                                    required
                                    disabled={submitLoading}
                                    onChange={(e) =>
                                        setQuestion({
                                            ...question,
                                            Targeted_Exam: e.target.value
                                        })
                                    }
                                    className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                >
                                    {labels && labels['targeted_exam'] && labels['targeted_exam'].length > 0 ? (
                                        labels['targeted_exam'].map((value, key) => {
                                            if (value && value !== '' && value !== ' ' && value !== 'N/A') {
                                                return (
                                                    <option value={value} key={key}>
                                                        {value}
                                                    </option>
                                                );
                                            }
                                        })
                                    ) : (
                                        <option value="N/A">N/A</option>
                                    )}
                                </select>
                            </div>
                            <div className="relative">
                                <label for="DifficultyLevel" className="block mb-2 text-sm font-medium text-gray-700 ">
                                    Difficulty Level
                                </label>
                                <select
                                    id="DifficultyLevel"
                                    value={question.DifficultyLevel}
                                    required
                                    disabled={submitLoading}
                                    onChange={(e) =>
                                        setQuestion({
                                            ...question,
                                            DifficultyLevel: e.target.value
                                        })
                                    }
                                    className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                >
                                    <option selected value="0">
                                        0
                                    </option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="N/A">N/A</option>
                                </select>
                            </div>
                            <div className="relative w-full">
                                <label for="Correct_Option" className="block mb-2 text-sm font-medium text-gray-700 ">
                                    Correct Option
                                </label>
                                <select
                                    id="Correct_Option"
                                    value={question.CorrectOption}
                                    required
                                    disabled={submitLoading}
                                    onChange={(e) =>
                                        setQuestion({
                                            ...question,
                                            CorrectOption: e.target.value
                                        })
                                    }
                                    className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                >
                                    <option selected value="1">
                                        A
                                    </option>
                                    <option value="2">B</option>
                                    <option value="3">C</option>
                                    <option value="4">D</option>
                                </select>
                            </div>
                            <div className="relative w-full">
                                <label className="block mb-2 text-sm font-medium text-gray-700 ">Correct Marks</label>
                                <input
                                    type="number"
                                    disabled
                                    className="mr-0 w-full relative inline-flex items-center justify-between px-2 py-2  text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    min="1"
                                    max="100"
                                    value={question.CorrectMarks}
                                    onChange={(e) => setQuestion({ ...question, CorrectMarks: e.target.value })}
                                />
                            </div>
                            <div className="relative w-full">
                                <label className="block mb-2 text-sm font-medium text-gray-700 ">Wrong Marks</label>
                                <input
                                    type="number"
                                    disabled
                                    value={question.WrongMarks}
                                    onChange={(e) => setQuestion({ ...question, WrongMarks: e.target.value })}
                                    id="small_outlined"
                                    className="mr-0 w-full relative inline-flex items-center justify-between px-2 py-2  text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    placeholder=" "
                                    required
                                    style={{ appearance: 'number-input' }} // Add this style
                                />
                            </div>
                        </div>
                        <div className="grid md:grid-cols-3 grid-cols-1 gap-5 mt-5">
                            <div className="grid md:grid-cols-2 grid-cols-1 col-span-3 gap-5">
                                <div className="relative w-full">
                                    <label for="QuestionType" className="block mb-2 text-sm font-medium text-gray-700 ">
                                        Question Type
                                    </label>
                                    <select
                                        id="QuestionType"
                                        value={question.QuestionType}
                                        required
                                        disabled={submitLoading}
                                        onChange={(e) =>
                                            setQuestion({
                                                ...question,
                                                QuestionType: e.target.value
                                            })
                                        }
                                        className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                    >
                                        <option selected value="Descriptive">
                                            Without Image
                                        </option>
                                        <option value="Image">Image</option>
                                    </select>
                                </div>
                            </div>
                            {question.QuestionType === 'Image' ? (
                                <div className="col-span-3 grid md:grid-cols-3 grid-cols-1 gap-5">
                                    <div className="relative col-span-3">
                                        <label for="QuestionImage" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Question Image
                                        </label>
                                        <input
                                            id="QuestionImage"
                                            type="file"
                                            required
                                            name="QuestionImage"
                                            disabled={submitLoading}
                                            accept="image/*"
                                            onChange={(e) => {
                                                handleFileChange(e);
                                            }}
                                            className="block p-1.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        />
                                    </div>
                                    {imageUrl.length > 0 && (
                                        <div className="col-span-3">
                                            <label for="QuestionImage" className="block mb-1 text-sm font-medium text-gray-700 ">
                                                Question Image
                                            </label>
                                            <img src={imageUrl} alt="Uploaded Image" style={{ width: 'auto', height: '300px' }} />
                                        </div>
                                    )}
                                </div>
                            ) : null}
                            <div className="relative">
                                <label for="QuestionInEng" className="block mb-1 text-sm font-medium text-gray-700 ">
                                    Question In English
                                </label>
                                <textarea
                                    id="QuestionInEng"
                                    rows="2"
                                    value={question.QuestionInEng}
                                    required
                                    disabled={submitLoading}
                                    onChange={(e) => {
                                        setQuestion({
                                            ...question,
                                            QuestionInEng: e.target.value
                                        });
                                    }}
                                    className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                ></textarea>
                            </div>
                            <div className="relative">
                                <label for="QuestionInHin" className="block mb-1 text-sm font-medium text-gray-700 ">
                                    Question In Hindi
                                </label>
                                <textarea
                                    id="QuestionInHin"
                                    rows="2"
                                    value={question.QuestionInHin}
                                    required
                                    disabled={submitLoading}
                                    onChange={(e) => {
                                        setQuestion({
                                            ...question,
                                            QuestionInHin: e.target.value
                                        });
                                    }}
                                    className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                ></textarea>
                            </div>
                            <div className="relative">
                                <label for="QuestionInGuj" className="block mb-1 text-sm font-medium text-gray-700 ">
                                    Question In Gujarati
                                </label>
                                <textarea
                                    id="QuestionInGuj"
                                    rows="2"
                                    value={question.QuestionInGuj}
                                    required
                                    disabled={submitLoading}
                                    onChange={(e) => {
                                        setQuestion({
                                            ...question,
                                            QuestionInGuj: e.target.value
                                        });
                                    }}
                                    className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                ></textarea>
                            </div>
                            <div className="col-span-3 grid md:grid-cols-2 grid-cols-1 gap-5">
                                <div className="relative w-full">
                                    <label for="OptionType" className="block mb-2 text-sm font-medium text-gray-700 ">
                                        Option A Type
                                    </label>
                                    <select
                                        id="OptionType"
                                        value={question.OptionAType}
                                        required
                                        disabled={submitLoading}
                                        onChange={(e) =>
                                            setQuestion({
                                                ...question,
                                                OptionAType: e.target.value
                                            })
                                        }
                                        className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                    >
                                        <option selected value="Descriptive">
                                            Non-Image
                                        </option>
                                        <option value="Image">Image</option>
                                    </select>
                                </div>
                            </div>
                            {question.OptionAType === 'Image' ? (
                                <div className="col-span-3 grid grid-cols-1 gap-5">
                                    <div className="relative">
                                        <label for="OptionImage" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option A Image
                                        </label>
                                        <input
                                            id="OptionAImage"
                                            type="file"
                                            name="OptionAImage"
                                            required
                                            accept="image/*"
                                            disabled={submitLoading}
                                            onChange={(e) => handleFileChange(e)}
                                            className="block p-1.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        />
                                    </div>
                                    {optionAUrl.length > 0 && (
                                        <div className="col-span-3">
                                            <label for="OptionImage" className="block mb-1 text-sm font-medium text-gray-700 ">
                                                Option A Image
                                            </label>
                                            <img src={optionAUrl} alt="Uploaded Image" style={{ width: 'auto', height: '150px' }} />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <div className="relative">
                                        <label for="OptionAInEng" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option A In English
                                        </label>
                                        <textarea
                                            id="OptionAInEng"
                                            rows="2"
                                            value={question.OptionAInEng}
                                            required
                                            disabled={submitLoading}
                                            onChange={(e) => {
                                                setQuestion({
                                                    ...question,
                                                    OptionAInEng: e.target.value
                                                });
                                            }}
                                            className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        ></textarea>
                                    </div>
                                    <div className="relative">
                                        <label for="OptionAInHin" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option A In Hindi
                                        </label>
                                        <textarea
                                            id="OptionAInHin"
                                            rows="2"
                                            value={question.OptionAInHin}
                                            required
                                            disabled={submitLoading}
                                            onChange={(e) => {
                                                setQuestion({
                                                    ...question,
                                                    OptionAInHin: e.target.value
                                                });
                                            }}
                                            className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        ></textarea>
                                    </div>
                                    <div className="relative">
                                        <label for="OptionAInGuj" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option A In Gujarati
                                        </label>
                                        <textarea
                                            id="OptionAInGuj"
                                            rows="2"
                                            value={question.OptionAInGuj}
                                            required
                                            disabled={submitLoading}
                                            onChange={(e) => {
                                                setQuestion({
                                                    ...question,
                                                    OptionAInGuj: e.target.value
                                                });
                                            }}
                                            className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        ></textarea>
                                    </div>
                                </>
                            )}
                            <div className="col-span-3 grid md:grid-cols-2 grid-cols-1 gap-5">
                                <div className="relative w-full">
                                    <label for="OptionType" className="block mb-2 text-sm font-medium text-gray-700 ">
                                        Option B Type
                                    </label>
                                    <select
                                        id="OptionType"
                                        value={question.OptionBType}
                                        required
                                        disabled={submitLoading}
                                        onChange={(e) =>
                                            setQuestion({
                                                ...question,
                                                OptionBType: e.target.value
                                            })
                                        }
                                        className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                    >
                                        <option selected value="Descriptive">
                                            Non-Image
                                        </option>
                                        <option value="Image">Image</option>
                                    </select>
                                </div>
                            </div>
                            {question.OptionBType === 'Image' ? (
                                <div className="col-span-3 grid grid-cols-1 gap-5">
                                    <div className="relative">
                                        <label for="OptionImage" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option B Image
                                        </label>
                                        <input
                                            id="OptionBImage"
                                            type="file"
                                            name="OptionBImage"
                                            required
                                            accept="image/*"
                                            disabled={submitLoading}
                                            onChange={(e) => handleFileChange(e)}
                                            className="block p-1.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        />
                                    </div>
                                    {optionBUrl.length > 0 && (
                                        <div className="col-span-3">
                                            <label for="OptionImage" className="block mb-1 text-sm font-medium text-gray-700 ">
                                                Option B Image
                                            </label>
                                            <img src={optionBUrl} alt="Uploaded Image" style={{ width: 'auto', height: '150px' }} />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <div className="relative">
                                        <label for="OptionBInEng" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option B In English
                                        </label>
                                        <textarea
                                            id="OptionBInEng"
                                            rows="2"
                                            value={question.OptionBInEng}
                                            required
                                            disabled={submitLoading}
                                            onChange={(e) => {
                                                setQuestion({
                                                    ...question,
                                                    OptionBInEng: e.target.value
                                                });
                                            }}
                                            className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        ></textarea>
                                    </div>
                                    <div className="relative">
                                        <label for="OptionBInHin" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option B In Hindi
                                        </label>
                                        <textarea
                                            id="OptionBInHin"
                                            rows="2"
                                            value={question.OptionBInHin}
                                            required
                                            disabled={submitLoading}
                                            onChange={(e) => {
                                                setQuestion({
                                                    ...question,
                                                    OptionBInHin: e.target.value
                                                });
                                            }}
                                            className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        ></textarea>
                                    </div>
                                    <div className="relative">
                                        <label for="OptionBInGuj" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option B In Gujarati
                                        </label>
                                        <textarea
                                            id="OptionBInGuj"
                                            rows="2"
                                            value={question.OptionBInGuj}
                                            required
                                            disabled={submitLoading}
                                            onChange={(e) => {
                                                setQuestion({
                                                    ...question,
                                                    OptionBInGuj: e.target.value
                                                });
                                            }}
                                            className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        ></textarea>
                                    </div>
                                </>
                            )}
                            <div className="col-span-3 grid md:grid-cols-2 grid-cols-1 gap-5">
                                <div className="relative w-full">
                                    <label for="OptionType" className="block mb-2 text-sm font-medium text-gray-700 ">
                                        Option C Type
                                    </label>
                                    <select
                                        id="OptionType"
                                        value={question.OptionCType}
                                        required
                                        disabled={submitLoading}
                                        onChange={(e) =>
                                            setQuestion({
                                                ...question,
                                                OptionCType: e.target.value
                                            })
                                        }
                                        className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                    >
                                        <option selected value="Descriptive">
                                            Non-Image
                                        </option>
                                        <option value="Image">Image</option>
                                    </select>
                                </div>
                            </div>
                            {question.OptionCType === 'Image' ? (
                                <div className="col-span-3 grid grid-cols-1 gap-5">
                                    <div className="relative">
                                        <label for="OptionImage" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option C Image
                                        </label>
                                        <input
                                            id="OptionCImage"
                                            type="file"
                                            name="OptionCImage"
                                            required
                                            accept="image/*"
                                            disabled={submitLoading}
                                            onChange={(e) => handleFileChange(e)}
                                            className="block p-1.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        />
                                    </div>
                                    {optionCUrl.length > 0 && (
                                        <div className="col-span-3">
                                            <label for="OptionImage" className="block mb-1 text-sm font-medium text-gray-700 ">
                                                Option C Image
                                            </label>
                                            <img src={optionCUrl} alt="Uploaded Image" style={{ width: 'auto', height: '150px' }} />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <div className="relative">
                                        <label for="OptionCInEng" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option C In English
                                        </label>
                                        <textarea
                                            id="OptionCInEng"
                                            rows="2"
                                            value={question.OptionCInEng}
                                            required
                                            disabled={submitLoading}
                                            onChange={(e) => {
                                                setQuestion({
                                                    ...question,
                                                    OptionCInEng: e.target.value
                                                });
                                            }}
                                            className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        ></textarea>
                                    </div>
                                    <div className="relative">
                                        <label for="OptionCInHin" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option C In Hindi
                                        </label>
                                        <textarea
                                            id="OptionCInHin"
                                            rows="2"
                                            value={question.OptionCInHin}
                                            required
                                            disabled={submitLoading}
                                            onChange={(e) => {
                                                setQuestion({
                                                    ...question,
                                                    OptionCInHin: e.target.value
                                                });
                                            }}
                                            className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        ></textarea>
                                    </div>
                                    <div className="relative">
                                        <label for="OptionCInGuj" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option C In Gujarati
                                        </label>
                                        <textarea
                                            id="OptionCInGuj"
                                            rows="2"
                                            value={question.OptionCInGuj}
                                            required
                                            disabled={submitLoading}
                                            onChange={(e) => {
                                                setQuestion({
                                                    ...question,
                                                    OptionCInGuj: e.target.value
                                                });
                                            }}
                                            className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        ></textarea>
                                    </div>
                                </>
                            )}
                            <div className="col-span-3 grid md:grid-cols-2 grid-cols-1 gap-5">
                                <div className="relative w-full">
                                    <label for="OptionType" className="block mb-2 text-sm font-medium text-gray-700 ">
                                        Option D Type
                                    </label>
                                    <select
                                        id="OptionType"
                                        value={question.OptionDType}
                                        required
                                        disabled={submitLoading}
                                        onChange={(e) =>
                                            setQuestion({
                                                ...question,
                                                OptionDType: e.target.value
                                            })
                                        }
                                        className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                    >
                                        <option selected value="Descriptive">
                                            Non-Image
                                        </option>
                                        <option value="Image">Image</option>
                                    </select>
                                </div>
                            </div>
                            {question.OptionDType === 'Image' ? (
                                <div className="col-span-3 grid grid-cols-1 gap-5">
                                    <div className="relative">
                                        <label for="OptionImage" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option D Image
                                        </label>
                                        <input
                                            id="OptionDImage"
                                            type="file"
                                            name="OptionDImage"
                                            required
                                            disabled={submitLoading}
                                            accept="image/*"
                                            onChange={(e) => handleFileChange(e)}
                                            className="block p-1.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        />
                                    </div>
                                    {optionDUrl.length > 0 && (
                                        <div className="col-span-3">
                                            <label for="OptionImage" className="block mb-1 text-sm font-medium text-gray-700 ">
                                                Option D Image
                                            </label>
                                            <img src={optionDUrl} alt="Uploaded Image" style={{ width: 'auto', height: '150px' }} />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <div className="relative">
                                        <label for="OptionDInEng" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option D In English
                                        </label>
                                        <textarea
                                            id="OptionDInEng"
                                            rows="2"
                                            value={question.OptionDInEng}
                                            required
                                            disabled={submitLoading}
                                            onChange={(e) => {
                                                setQuestion({
                                                    ...question,
                                                    OptionDInEng: e.target.value
                                                });
                                            }}
                                            className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        ></textarea>
                                    </div>
                                    <div className="relative">
                                        <label for="OptionDInHin" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option D In Hindi
                                        </label>
                                        <textarea
                                            id="OptionDInHin"
                                            rows="2"
                                            value={question.OptionDInHin}
                                            required
                                            disabled={submitLoading}
                                            onChange={(e) => {
                                                setQuestion({
                                                    ...question,
                                                    OptionDInHin: e.target.value
                                                });
                                            }}
                                            className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        ></textarea>
                                    </div>
                                    <div className="relative">
                                        <label for="OptionDInGuj" className="block mb-1 text-sm font-medium text-gray-700 ">
                                            Option D In Gujarati
                                        </label>
                                        <textarea
                                            id="OptionDInGuj"
                                            rows="2"
                                            value={question.OptionDInGuj}
                                            required
                                            disabled={submitLoading}
                                            onChange={(e) => {
                                                setQuestion({
                                                    ...question,
                                                    OptionDInGuj: e.target.value
                                                });
                                            }}
                                            className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        ></textarea>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className=" flex flex-col items-center mt-5 justify-center w-full">
                            <button
                                type="submit"
                                disabled={submitLoading || loading}
                                className=" text-white bg-blue-700 hover:bg-blue-900 focus:ring-2 focus:ring-blue-300 font-medium rounded-lg text-base px-4 py-1.5 mx-auto mb-2 mt-1  focus:outline-none "
                                onClick={(e) => {
                                    handleQuestionEdit(e);
                                }}
                            >
                                {submitLoading ? (
                                    <div className="inline-flex items-center">
                                        <svg
                                            aria-hidden="true"
                                            role="status"
                                            className="w-4 h-4 mr-3 text-white animate-spin"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                        <span className="text-white">Loading...</span>
                                    </div>
                                ) : (
                                    'Edit Question'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </MainCard>
        </div>
    );
}

export default UpdateQuestion;
