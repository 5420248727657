import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import endPoint from "../../../../routes/NetworkRoutes";
import { debounce } from "lodash";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  Card,
  Grid,
  InputAdornment,
  OutlinedInput,
  Popper,
} from "@mui/material";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "ui-component/extended/Transitions";

// assets
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: "99%",
  top: "-55px !important",
  padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 500,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background: "#fff",
    },
  })
);

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.light,
    },
  })
);

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
  const theme = useTheme();

  return (
    <OutlineInputStyle
      id="input-search-header"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Search"
      startAdornment={
        <InputAdornment position="start">
          <IconSearch
            stroke={1.5}
            size="1rem"
            color={theme.palette.grey[500]}
          />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          {/* <ButtonBase sx={{ borderRadius: '12px' }}>
                        <HeaderAvatarStyle variant="rounded">
                            <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
                        </HeaderAvatarStyle>
                    </ButtonBase> */}
          <Box sx={{ ml: 2 }}>
            <ButtonBase sx={{ borderRadius: "12px" }}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  background: theme.palette.orange.light,
                  color: theme.palette.orange.dark,
                  "&:hover": {
                    background: theme.palette.orange.dark,
                    color: theme.palette.orange.light,
                  },
                }}
                {...bindToggle(popupState)}
              >
                <IconX stroke={1.5} size="1.3rem" />
              </Avatar>
            </ButtonBase>
          </Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ "aria-label": "weight" }}
    />
  );
};

MobileSearch.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  popupState: PopupState,
};

// ==============================|| SEARCH INPUT ||============================== //

const SearchSection = () => {
  const theme = useTheme();
  const [value, setValue] = useState("");
  const [suggestedList, setSuggestedList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const debouncedSearch = debounce(async (term) => {
      // Perform your search operation here, like fetching suggestions from an API
      const fetchedSuggestions = await getSuggestedList(term);
      setSuggestedList(fetchedSuggestions);
    }, 500); // Adjust the debounce delay as needed (e.g., 300 milliseconds)

    // Call the debounced function whenever searchTerm changes
    debouncedSearch(value);

    // Clean up function to cancel any pending debounce calls when component unmounts
    return () => {
      debouncedSearch.cancel();
    };
  }, [value]);

  const getSuggestedList = async (searchTerm) => {
    try {
      if (searchTerm === "") {
        return [];
      }
      let userData = JSON.parse(window.localStorage.getItem("User State"));
      let response = await axios.get(
        endPoint.getStudentSearchList + searchTerm,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      console.log(response.data.data);
      const suggestions = response.data.data.map((suggestion) => ({
        sid: suggestion.SID,
        value:
          suggestion.SID +
          " - " +
          suggestion.FirstName +
          " " +
          suggestion.LastName,
      }));
      return suggestions;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const handleOptionClick = (option) => {
    setValue("");
    navigate("studentProfile/" + option.sid);
  };

  // useEffect(() => {
  //     getSuggestedList();
  // }, []);

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <>
              <Box sx={{ ml: 2 }}>
                <ButtonBase sx={{ borderRadius: "12px" }}>
                  <HeaderAvatarStyle
                    variant="rounded"
                    {...bindToggle(popupState)}
                  >
                    <IconSearch stroke={1.5} size="1.2rem" />
                  </HeaderAvatarStyle>
                </ButtonBase>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <>
                    <Transitions
                      type="zoom"
                      {...TransitionProps}
                      sx={{ transformOrigin: "center left" }}
                    >
                      <Card
                        sx={{
                          background: "#fff",
                          [theme.breakpoints.down("sm")]: {
                            border: 0,
                            boxShadow: "none",
                          },
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item xs>
                              <MobileSearch
                                value={value}
                                setValue={setValue}
                                popupState={popupState}
                              />
                              <Autocomplete
                                freeSolo
                                options={suggestedList}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Search"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </PopperStyle>
            </>
          )}
        </PopupState>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Autocomplete
          freeSolo
          options={suggestedList}
          getOptionLabel={(option) => option.value}
          onInputChange={(e, newValue) => setValue(newValue)} // Handle input value changes
          renderInput={(params) => (
            <OutlineInputStyle
              id="input-search-header"
              placeholder="Search student by SID"
              aria-describedby="search-helper-text"
              inputProps={{ ...params.inputProps, "aria-label": "weight" }} // Pass Autocomplete inputProps
              {...params.InputProps} // Spread Autocomplete InputProps
            />
          )}
          renderOption={(props, option) => (
            <li
              {...props}
              onClick={(event) => {
                setValue("");
                navigate("studentProfile/" + option.sid);
              }}
            >
              <span>{option.value}</span>
              <IconSearch
                stroke={1.5}
                size="1rem"
                color={theme.palette.grey[500]}
              />
            </li>
          )}
        />
      </Box>
    </>
  );
};

export default SearchSection;
