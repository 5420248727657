import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer.js';
// import { setUserData, clearUserData } from './User/store.js';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
    reducer: reducer
});



const persister = 'Free';

export { store, persister };
