import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { Navigate } from 'react-router-dom';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const Greet = Loadable(lazy(() => import('views/pages/authentication/authentication3/GreetPage')));
const ChangePassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ChangePassword')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',

    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLogin3 />
        },
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/register',
            element: <AuthRegister3 />
        },
        {
            path: '/greet',
            element: <Greet />
        },
        {
            path: '/changePassword',
            element: <ChangePassword />
        },
        {
            path: '*',
            element: <Navigate to="/login" replace />
        }
    ]
};

export default AuthenticationRoutes;
