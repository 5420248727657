// assets
import { IconDashboard } from "@tabler/icons";

import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ArticleIcon from "@mui/icons-material/Article";

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "userDashboard",
  // title: 'Dashboard',
  type: "group",
  children: [
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "/userDashboard",
      icon: DashboardRoundedIcon,
      breadcrumbs: false,
    },
    {
      id: "grivance",
      title: "Raise Grivance",
      type: "collapse",
      url: "/userDashboard/grivance",
      icon: ArticleIcon,
      children: [
        {
          id: "raiseGrivance",
          title: "Raise Grivance",
          type: "item",
          url: "/userDashboard/grivance/raiseGrivance",
          breadcrumbs: false,
        },
        {
          id: "myRaisedGrivance",
          title: "My Raised Grivance",
          type: "item",
          url: "/userDashboard/grivance/myRaisedGrivance",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "readingReport",
      title: "Reading Report",
      type: "collapse",
      url: "/userDashboard/readingReport",
      icon: ArticleIcon,
      children: [
        {
          id: "enterReadingDetails",
          title: "Enter Daily Update",
          type: "item",
          url: "/userDashboard/readingReport/enterDailyUpdate",
          breadcrumbs: false,
        },
        {
          id: "seeAllUpdateStatus",
          title: "All Reading Updates",
          type: "item",
          url: "/userDashboard/readingReport/allReadingUpdates",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "disciplinaryAction",
      title: "Disciplinary Action",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "MyDisciplinaryAction",
          title: "My Disciplinary Actions",
          type: "item",
          url: "/userDashboard/disciplinaryAction/myDisciplinaryActions",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "cancelAdmission",
      title: "Cancel Admission",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "cancelAdmissionRequest",
          title: "Cancel Admission Request",
          type: "item",
          url: "/userDashboard/cancelAdmission/cancelAdmissionRequest",
          breadcrumbs: true,
        },
        {
          id: "allCancelAdmissionRequest",
          title: "All Cancel Admission Request",
          type: "item",
          url: "/userDashboard/cancelAdmission/allCancelAdmissionRequest",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "leave",
      title: "Manage Leave",
      type: "collapse",
      url: "/userDashboard/manageLeave",
      icon: DashboardRoundedIcon,
      children: [
        {
          id: "manageLeave",
          title: "Manage Leave",
          type: "item",
          url: "/userDashboard/manageLeave/manageLeave",
          breadcrumbs: false,
        },
        {
          id: "raiseLeave",
          title: "Raise Leave",
          type: "item",
          url: "/userDashboard/manageLeave/raiseLeave",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "studentPerformence",
      title: "Student Performence",
      type: "collapse",
      url: "/userDashboard/studentPerformence",
      icon: DashboardRoundedIcon,
      children: [
        {
          id: "myPerformence",
          title: "My Performence",
          type: "item",
          url: "/userDashboard/studentPerformence/myPerformence",
          breadcrumbs: false,
        },
      ],
    },
    // {
    //     id: 'library',
    //     title: 'Library',
    //     type: 'collapse',
    //     icon: ArticleIcon,
    //     // breadcrumbs: false
    //     children: [
    //         {
    //             id: 'requestBook',
    //             title: 'Request Book',
    //             type: 'item',
    //             url: '/userDashboard/library/requestBook',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'requestStatus',
    //             title: 'Request Status',
    //             type: 'item',
    //             url: '/userDashboard/library/requestStatus',
    //             breadcrumbs: false
    //         }
    //     ]
    // },

    // {
    //     id: 'entranceExam',
    //     title: 'Entrance Exam',
    //     type: 'collapse',
    //     icon: ArticleIcon,
    //     // breadcrumbs: false
    //     children: [
    //         {
    //             id: 'createExam',
    //             title: 'Create Exam',
    //             type: 'item',
    //             url: '/dashboard/entranceExam/createExam',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'All Entrance Exams',
    //             title: 'All Exams',
    //             type: 'item',
    //             url: '/dashboard/entranceExam/allExams',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'Set Questions',
    //             title: 'EntranceQuestions',
    //             type: 'item',
    //             url: '/dashboard/entranceExam/setQuestions',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    // {
    //     id: 'regularExam',
    //     title: 'Regular Exam',
    //     type: 'collapse',
    //     icon: ArticleIcon,
    //     // breadcrumbs: false
    //     children: [
    //         {
    //             id: 'allocateExam',
    //             title: 'Allocate Exam',
    //             type: 'item',
    //             url: '/dashboard/regularExam/allocateExam',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'createExam',
    //             title: 'Create Exam',
    //             type: 'item',
    //             url: '/dashboard/regularExam/createExam',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'All Entrance Exams',
    //             title: 'All Exams',
    //             type: 'item',
    //             url: '/dashboard/regularExam/allExams',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'Set Questions',
    //             title: 'EntranceQuestions',
    //             type: 'item',
    //             url: '/dashboard/regularExam/setQuestions',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    //  {
    //     id: 'VerifyStudent',
    //     title: 'Verify Student',
    //     type: 'collapse',
    //     icon: ArticleIcon,
    //     // breadcrumbs: false
    //     children: [
    //         {
    //             id: 'Unverified Students',
    //             title: 'Unverified Students',
    //             type: 'item',
    //             url: '/dashboard/verifyStudent/unverifiedStudents',
    //             breadcrumbs: false
    //         },

    //     ]
    // },
    // {
    //     id: 'Batch',
    //     title: 'Batches',
    //     type: 'collapse',
    //     icon: ArticleIcon,
    //     // breadcrumbs: false
    //     children: [
    //         {
    //             id: 'Create Batch',
    //             title: 'Create Batch',
    //             type: 'item',
    //             url: '/dashboard/batch/createBatch',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'All Batches',
    //             title: 'All Batches',
    //             type: 'item',
    //             url: '/dashboard/batch/allBatches',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'Generate Merit',
    //             title: 'Generate Merit',
    //             type: 'item',
    //             url: '/dashboard/batch/generateMerit',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'Upload Result',
    //             title: 'Upload Result',
    //             type: 'item',
    //             url: '/dashboard/batch/uploadResult',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    // {
    //     id: 'Admission',
    //     title: 'Admission',
    //     type: 'collapse',
    //     icon: ArticleIcon,
    //     // breadcrumbs: false
    //     children: [
    //         {
    //             id: 'Register For Admission',
    //             title: 'Register For Admission',
    //             type: 'item',
    //             url: '/dashboard/admission/registerForAdmission',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    // {
    //     id: 'Questions Bank',
    //     title: 'Question Bank',
    //     type: 'collapse',
    //     icon: ArticleIcon,
    //     // breadcrumbs: false
    //     children: [
    //         {
    //             id: 'Create New Question',
    //             title: 'Create New Question',
    //             type: 'item',
    //             url: '/dashboard/questionBank/createQuestion',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'AllQuestions',
    //             title: 'All Questions',
    //             type: 'item',
    //             url: '/dashboard/questionBank/allQuestions',
    //             breadcrumbs: false
    //         }
    //     ]
    // }
  ],
};

export default dashboard;
