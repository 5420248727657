import dashboard from "./dashboard";
import admin from "./admin";
import faculty from "./faculty.js";
import superAdmin from "./superadmin.js";
import staff from "./staff.js";
import pages from "./pages";
import utilities from "./utilities";
import other from "./other";
import dataEntry from "./dataEntry";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard],
  adminIems: [admin],
  superAdmin: [superAdmin],
  faculty: [faculty],
  staff: [staff],
  dataEntry: [dataEntry],
};

export default menuItems;
