import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from '../../../config';
import Logo from 'ui-component/Logo';
import { MENU_OPEN } from 'store/actions';
import { useNavigate } from 'react-router-dom';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const navigate = useNavigate();
    const defaultId = useSelector((state) => state.customization.defaultId);
    const dispatch = useDispatch();
    return (
        <ButtonBase
            disableRipple
            onClick={() => {
                navigate('/');
                console.log('clicked');
                dispatch({ type: MENU_OPEN, id: defaultId });
            }}
            component={Link}
            to={config.defaultPath}
        >
            <Link to="/login">
                <Logo className="w-10" />
            </Link>
        </ButtonBase>
    );
};

export default LogoSection;
