import { createRoot } from "react-dom/client";
import "./index.css";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

// project imports
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import App from "./App";

// style + assets
import "./assets/scss/style.scss";
import config from "./config";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <App />
    </BrowserRouter>
  </Provider>
);

serviceWorker.register();
