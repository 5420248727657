import { Provider, useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// routing
import Routes from "./routes";

// defaultTheme
import themes from "./themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";

//store
import { store } from "./store/index";

import axios from "axios";
import endPoint from "./routes/NetworkRoutes";
import { useNavigate } from "react-router";
import { useEffect } from "react";

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  useEffect(() => {
    let userData = JSON.parse(window.localStorage.getItem("User State"));
    if (!userData) {
      navigate("/login");
    } else {
      try {
        let response = axios.get(endPoint.getAllBooks, {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        });
      } catch (error) {
        console.error(error);
        navigate("/login");
        window.localStorage.removeItem("User State");
      }
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Provider store={store}>
            <Routes />
          </Provider>
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
