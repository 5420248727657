const SkeletonPage = (item) => {
    console.log('SkeletonPage');
    console.log(item);
    if (item.item == 'card') {
        console.log('card');
        return (
            <div role="status" className="space-y-5 w-9/10 rounded-lg bg-white  p-5 animate-pulse ">
                <div className="flex items-center w-full space-x-2">
                    <div className="h-2.5 bg-gray-200 rounded-full  w-32"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full  w-full"></div>
                </div>
                <div className="flex items-center w-full space-x-2 max-w-[480px]">
                    <div className="h-2.5 bg-gray-200 rounded-full  w-full"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full  w-full"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
                <div className="flex items-center w-full space-x-2 max-w-[400px]">
                    <div className="h-2.5 bg-gray-300 rounded-full  w-full"></div>
                    <div className="h-2.5 bg-gray-200 rounded-full  w-80"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full  w-full"></div>
                </div>
                <div className="flex items-center w-full space-x-2 max-w-[480px]">
                    <div className="h-2.5 bg-gray-200 rounded-full  w-full"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full  w-full"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                </div>
                <div className="flex items-center w-full space-x-2 max-w-[440px]">
                    <div className="h-2.5 bg-gray-300 rounded-full  w-32"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full  w-24"></div>
                    <div className="h-2.5 bg-gray-200 rounded-full  w-full"></div>
                </div>
                <div className="flex items-center w-full space-x-2 max-w-[360px]">
                    <div className="h-2.5 bg-gray-300 rounded-full  w-full"></div>
                    <div className="h-2.5 bg-gray-200 rounded-full  w-80"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full  w-full"></div>
                </div>
                <span className="sr-only">Loading...</span>
            </div>
        );
    } else if (item.item == 'list') {
        return (
        <div
            role="status"
            className="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded-xl shadow animate-pulse bg-white                                  "
        >
            <div className="flex items-center justify-between">
                <div>
                    <div className="h-2.5 bg-gray-300 rounded-full  w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
                </div>
                <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
                <div>
                    <div className="h-2.5 bg-gray-300 rounded-full  w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
                </div>
                <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
                <div>
                    <div className="h-2.5 bg-gray-300 rounded-full  w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
                </div>
                <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
                <div>
                    <div className="h-2.5 bg-gray-300 rounded-full  w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
                </div>
                <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <div className="flex items-center justify-between pt-4">
                <div>
                    <div className="h-2.5 bg-gray-300 rounded-full  w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full "></div>
                </div>
                <div className="h-2.5 bg-gray-300 rounded-full  w-12"></div>
            </div>
            <span className="sr-only">Loading...</span>
        </div>
        );
    }
};
export default SkeletonPage;
