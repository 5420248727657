import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import axios from "axios";
import endPoint from "../../../routes/NetworkRoutes";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TablePagination } from "@mui/material";
import SkeletonPage from "ui-component/cards/Skeleton/Page";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MainCard from "ui-component/cards/MainCard";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material"; // Material-UI component for loading spinner

import { saveAs } from "file-saver";
import {
  Document,
  Page,
  pdf,
  Text,
  Image,
  View,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

function ViewStudent() {
  const { batchCodeParams } = useParams();
  const navigate = useNavigate();
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(window.localStorage.getItem("User State"));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(result);
  const [batchDetails, setBatchDetails] = useState();
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [downloading, setdownloading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const getStudentData = async (data) => {
    const SID = data?.personalDetails?.SID;
    const endPoints = endPoint.getProfileStudentData(SID);
    let details = {};
    try {
      setdownloading(true);
      const response = await axios.get(endPoints, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      });
      console.log(response.data.data);
      details = response?.data?.data;
      if (response.data.data.findDetails?.ProfileUrl?.length > 0) {
        const responseUrl = await axios.get(
          endPoint.getPresignedUrlToViewDocuments +
            "?key=" +
            response.data.data.findDetails?.ProfileUrl,
          {
            headers: {
              Authorization: `Bearer ${userData.token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        console.log(responseUrl);
        if (responseUrl.data.code == 200) {
          console.log(responseUrl.data.data);
          details["profileUrl"] = responseUrl.data.data.getUrl;
        }
      }
      if (response.data.data.findDetails?.SignatureUrl?.length > 0) {
        const responseUrl = await axios.get(
          endPoint.getPresignedUrlToViewDocuments +
            "?key=" +
            response.data.data.findDetails?.SignatureUrl,
          {
            headers: {
              Authorization: `Bearer ${userData.token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        console.log(responseUrl);
        if (responseUrl.data.code == 200) {
          console.log(responseUrl.data.data);
          details["signatureUrl"] = responseUrl.data.data.getUrl;
        }
      }
      if (response.data.data.educationdetails?.LcLink?.length > 0) {
        const responseUrl = await axios.get(
          endPoint.getPresignedUrlToViewDocuments +
            "?key=" +
            response.data.data.educationdetails?.LcLink,
          {
            headers: {
              Authorization: `Bearer ${userData.token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        console.log(responseUrl);
        if (responseUrl.data.code == 200) {
          console.log(responseUrl.data.data);
          details["lcLink"] = responseUrl.data.data.getUrl;
        }
      }

      const pdfBlob = await pdf(
        <StudentProfilePDF detail={details} />
      ).toBlob();

      // Save PDF
      if (pdfBlob) {
        saveAs(pdfBlob, `${SID}_Admission_Form.pdf`);
      } else {
        throw new Error("PDF generation failed");
      }
    } catch (error) {
      console.log(error);

      if (error.code === "ERR_BAD_RESPONSE") {
        toast.error(error.response.data.message);
        if (error.response.data.message === "jwt expired") {
          window.localStorage.clear();
        }
      } else {
        toast.error(error.message);
      }
    } finally {
      setdownloading(false);
    }
  };

  const styles = StyleSheet.create({
    logo: {
      width: 100,
      // height: "auto",
    },
    page: {
      padding: 15, // Reduced padding for the page
    },
    headerContainer: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 15,
    },
    logoContainer: {
      width: "30%",
    },
    companyDetailsContainer: {
      width: "70%",
      textAlign: "center",
    },
    companyName: {
      fontSize: 14, // Reduced font size
      fontWeight: "bold",
      marginBottom: 3,
    },
    companyAddress: {
      fontSize: 10, // Reduced font size
    },
    hr: {
      borderBottomWidth: 1,
      borderBottomColor: "#000",
      marginVertical: 8,
    },
    title: {
      fontSize: 18, // Reduced font size
      textAlign: "center",
      marginBottom: 15,
      textDecoration: "underline",
    },
    section: {
      marginBottom: 15,
    },
    personalDetailsContainer: {
      fontSize: 8, // Reduced font size
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 5,
    },
    personalDetails: {
      width: "60%",
      fontSize: 8, // Reduced font size
    },
    inputLabel: {
      fontSize: 8, // Reduced font size
      marginBottom: 2,
      fontWeight: "bold",
    },
    inputBox: {
      fontSize: 8, // Reduced font size
      padding: 2,
      borderWidth: 1,
      borderColor: "#000",
      marginBottom: 4,
    },
    signatureBox: {
      width: 100, // Reduced width
      height: 120, // Reduced height
      borderWidth: 1,
      padding: 6,
      borderColor: "#000",
    },
    photoBox: {
      width: 140, // Reduced width
      height: 160, // Reduced height
      borderWidth: 1,
      padding: 6,
      borderColor: "#000",
    },
    photoLabel: {
      textAlign: "center",
      marginTop: 3,
      fontSize: 10, // Reduced font size
    },
    table: {
      display: "table",
      width: "auto",
    },
    tableRow: {
      flexDirection: "row",
    },
    tableCol: {
      width: "25%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#000",
      padding: 3,
    },
    tableCell: {
      fontSize: 8, // Reduced font size
    },
    signature: {
      marginTop: 15,
      flexDirection: "row",
      justifyContent: "space-between",
    },
  });
  const TableCheck = ({ data, title, headers, fields }) => (
    <View style={styles.section}>
      <View style={styles.table}>
        {/* Table Header */}
        <View style={styles.tableRow}>
          {headers.map((header, index) => (
            <View style={styles.tableCol} key={index}>
              <Text style={[styles.tableCell, styles.tableHeader]}>
                {header}
              </Text>
            </View>
          ))}
        </View>
        {/* Table Rows */}
        {data.map((item, index) => (
          <View style={styles.tableRow} key={index}>
            {fields.map((field, idx) => (
              <View style={styles.tableCol} key={idx}>
                <Text style={styles.tableCell}>
                  {field.value(item) || "N/A"}
                </Text>
              </View>
            ))}
          </View>
        ))}
      </View>
    </View>
  );
  const StudentProfilePDF = ({ detail }) => {
    const data = detail;
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Header */}
          <View style={styles.headerContainer}>
            <View style={styles.logoContainer}>
              <Image
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9HjZjEKmvdB_fkffLClRIBc2JG5nFyPzWq0mCC_TPJKR-U_Od494raE2shPvHY_m3aIM&usqp=CAU"
                style={styles.logo}
              />
            </View>
            <View style={styles.companyDetailsContainer}>
              <Text style={styles.companyName}>
                Guidance Of Competitive Exams And Support For Aspirants
              </Text>
              <Text style={styles.companyAddress}>
                Complete Guidance And Coaching for Government Jobs
              </Text>
              <Text style={styles.companyAddress}>
                [ Education Trust, Regd. No.: E/8340/SURAT ]
              </Text>
            </View>
          </View>
          <View style={styles.hr} />

          {/* Title */}
          <View style={styles.titleContainer}>
            <Text style={styles.title}>ADMISSION FORM</Text>
          </View>

          {/* Personal Details */}
          <View style={styles.section}>
            <View style={styles.personalDetailsContainer}>
              <View style={styles.personalDetails}>
                <Text style={styles.inputLabel}>Student No</Text>
                <View style={styles.inputBox}>
                  <Text>{data?.findDetails?.SID}</Text>
                </View>
                <Text style={styles.inputLabel}>Name</Text>
                <View style={styles.inputBox}>
                  <Text>
                    {data?.findDetails?.FirstName}{" "}
                    {data?.findDetails?.MiddleName}{" "}
                    {data?.findDetails?.LastName}
                  </Text>
                </View>
                <Text style={styles.inputLabel}>Date of Birth</Text>
                <View style={styles.inputBox}>
                  <Text>
                    {new Date(data?.findDetails?.DOB)?.toLocaleDateString()}
                  </Text>
                </View>
                <Text style={styles.inputLabel}>Gender</Text>
                <View style={styles.inputBox}>
                  <Text>{data?.findDetails?.Gender}</Text>
                </View>
                <Text style={styles.inputLabel}>Marital Status</Text>
                <View style={styles.inputBox}>
                  <Text>
                    {data?.findDetails?.MartialStatus === "S"
                      ? "Single"
                      : "Married"}
                  </Text>
                </View>
                <Text style={styles.inputLabel}>Category</Text>
                <View style={styles.inputBox}>
                  <Text>{data?.findDetails?.Category}</Text>
                </View>
                <Text style={styles.inputLabel}>Blood Group</Text>
                <View style={styles.inputBox}>
                  <Text>{data?.findDetails?.BloodGroup}</Text>
                </View>
              </View>
              <View>
                {data?.profileUrl && (
                  <Image style={styles.photoBox} src={data.profileUrl} />
                )}
                <Text style={styles.photoLabel}>Photograph</Text>
              </View>
            </View>

            {/* Contact Details */}
            <Text style={styles.inputLabel}>Current Address</Text>
            <View style={styles.inputBox}>
              <Text>
                {data?.address?.CAdd1}, {data?.address?.CAdd2},{" "}
                {data?.address?.CAddCity}, {data?.address?.CAddPin}
              </Text>
            </View>

            <Text style={styles.inputLabel}>Permanent Address</Text>
            <View style={styles.inputBox}>
              <Text>
                {data?.address?.PAdd1}, {data?.address?.PAdd2},{" "}
                {data?.address?.PAddCity}, {data?.address?.PAddPin}
              </Text>
            </View>

            <Text style={styles.inputLabel}>Mobile No.</Text>
            <View style={styles.inputBox}>
              <Text>{data?.contactdetails?.MobileNo}</Text>
            </View>

            <Text style={styles.inputLabel}>WhatsApp No.</Text>
            <View style={styles.inputBox}>
              <Text>{data?.contactdetails?.WhatsAppNumber}</Text>
            </View>

            <Text style={styles.inputLabel}>Parent's Contact Nos.</Text>
            <View style={styles.inputBox}>
              <Text>{data?.contactdetails?.ParentsNo?.join(", ")}</Text>
            </View>

            <Text style={styles.inputLabel}>Email</Text>
            <View style={styles.inputBox}>
              <Text>{data?.contactdetails?.Email}</Text>
            </View>
          </View>

          {/* Academic Record */}
          <View style={styles.section}>
            <Text style={styles.inputLabel}>Academic Record</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>SSC Percentage</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {data?.educationdetails?.SSCPercentage?.$numberDecimal}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>HSC Percentage</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {data?.educationdetails?.HSCPercentage?.$numberDecimal}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Graduate Status</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {data?.educationdetails?.GradStatus == "C"
                      ? "Completed"
                      : "Pending"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Graduate University</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {data?.educationdetails?.GraduateUni}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Graduate Degree</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {data?.educationdetails?.GraduateDeg}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Percentage</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {data?.educationdetails?.GraduatePer?.$numberDecimal}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Post Graduate Status</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {data?.educationdetails?.PostGradStatus == "C"
                      ? "Completed"
                      : data?.educationdetails?.PostGradStatus == "P"
                      ? "Pending"
                      : "NA"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Post Graduate University</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {data?.educationdetails?.PostGraduateUni}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Post Graduate Degree</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {data?.educationdetails?.PostGraduateDeg}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Percentage</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {data?.educationdetails?.PostGraduatePer?.$numberDecimal}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          {/* Batch Details */}
          <View style={styles.section}>
            <Text style={styles.inputLabel}>Batch Details</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Batch Code</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Location</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Batch Name</Text>
                </View>
              </View>
              {data.BatchDetails.map((batch) => (
                <View key={batch._id} style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{batch?.BatchCode}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{batch?.Location}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{batch?.BatchName}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <Text style={styles.inputLabel}>Job Details</Text>
          <TableCheck
            data={data?.jobdetails?.JobTenure || []}
            title="Job Details"
            headers={[
              "Company Name",
              "Designation",
              "Description",
              "From",
              "To",
              "Status",
              "In Government Job",
              "Government Job Designation",
              "Office Name",
            ]}
            fields={[
              { value: (item) => item?.CompanyName },
              { value: (item) => item?.Designation },
              { value: (item) => item?.Description },
              { value: (item) => (item?.From ? item.From.toDateString() : "") },
              { value: (item) => (item?.To ? item.To.toDateString() : "") },
              { value: (item) => item?.Status },
              {
                value: (item) =>
                  item?.GovernmentJobDetails?.InGovernmentJob ? "Yes" : "No",
              },
              { value: (item) => item?.GovernmentJobDetails?.Designation },
              { value: (item) => item?.GovernmentJobDetails?.Office_Name },
            ]}
          />
          <Text style={styles.inputLabel}>Competative Exam Details</Text>
          <TableCheck
            data={data?.examDetails?.ExamDetails || []}
            title="Competitive Exam Details"
            headers={[
              "Exam Adv No",
              "Exam Name",
              "Conducting Body",
              "Roll No",
              "Outcome",
              "Appear In Exam",
            ]}
            fields={[
              { value: (item) => item?.ExamAdvNo },
              { value: (item) => item?.ExamName },
              { value: (item) => item?.ConductingBody },
              { value: (item) => item?.RollNo },
              { value: (item) => item?.Outcome },
              { value: (item) => item?.AppearInExam },
            ]}
          />

          {/* Signature */}
          <View style={styles.signature}>
            {data?.signatureUrl && (
              <Image style={styles.signatureBox} src={data.signatureUrl} />
            )}
          </View>
          <Text style={styles.inputLabel}>Applicant's Signature</Text>
        </Page>
      </Document>
    );
  };

  useEffect(() => {
    if (searchQuery === "") {
      setFilteredData(result);
    } else {
      setFilteredData(
        result.filter((student) => {
          const search = searchQuery.toLowerCase();
          return (
            student.personalDetails.SID.toLowerCase().includes(search) ||
            student.personalDetails.FirstName.toLowerCase().includes(search) ||
            student.personalDetails.LastName.toLowerCase().includes(search)
          );
        })
      );
    }
  }, [result, searchQuery]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleDownloadSingleForm = (student) => {
    // Set loading state for this specific record to true
    setLoadingStates((prev) => ({ ...prev, [student._id]: true }));

    // Trigger the download
    getStudentData(student).finally(() => {
      // Reset loading state for this record to false after download
      setLoadingStates((prev) => ({ ...prev, [student._id]: false }));
    });
  };

  const handleDownloadSelectedForms = () => {
    selectedStudents.forEach((student) => {
      // Set loading state for each selected record to true
      setLoadingStates((prev) => ({ ...prev, [student._id]: true }));

      // Trigger the download
      getStudentData(student).finally(() => {
        // Reset loading state for this record to false after download
        setLoadingStates((prev) => ({ ...prev, [student._id]: false }));
      });
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelect = (student) => {
    setSelectedStudents((prevSelected) =>
      prevSelected.includes(student)
        ? prevSelected.filter((s) => s !== student)
        : [...prevSelected, student]
    );
  };

  const getStudent = async () => {
    setLoading(true);
    try {
      const request = await axios.get(
        endPoint.getBatchStudent + batchCodeParams,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      if (request.data.code === 200) {
        setResult(request.data.data.response);
        setBatchDetails(request.data.data.batchDetails);
        toast.success("Result fetched successfully");
      }
    } catch (error) {
      if (error.code === "ERR_BAD_REQUEST") {
        if (error.response.data.message?.length > 0) {
          for (let i = 0; i < error.response.data.message.length; i++) {
            toast.error(error.response.data.message[i].msg);
          }
        } else {
          toast.error(error.response.data.message);
        }
      } else if (error.code === "ERR_BAD_RESPONSE") {
        toast.error(error.response.data.message);
        if (error.response.data.message === "jwt expired") {
          window.localStorage.clear();
          navigate("/login");
        }
      } else {
        toast.error(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStudent();
  }, []);

  return (
    <>
      {loading ? (
        <SkeletonPage item="list" />
      ) : (
        <div className="max-w-full max-h-full sm:px-2 px-2">
          <div className="max-w-full py-4 max-h-full md:px-2">
            <MainCard>
              <div className="relative flex flex-wrap">
                <div className="w-full relative">
                  <div className="md:mt-6">
                    <div className="text-center font-semibold text-xl mb-5 text-black">
                      {`${batchDetails && batchDetails.BatchName} Students`}
                    </div>

                    <label
                      htmlFor="search"
                      className="mb-2 text-sm font-medium text-gray-900 sr-only"
                    >
                      Search based on Student Name or Student No.
                    </label>
                    <div className="relative">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg
                          className="w-4 h-4 ml-2 text-gray-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <input
                        type="search"
                        id="search"
                        onChange={handleSearch}
                        value={searchQuery}
                        className="block w-full px-7 mb-4 py-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Search based on Student Name or Student No."
                        required
                      />
                    </div>

                    <TableContainer component={Paper}>
                      <Table aria-label="collapsible table border">
                        <TableHead>
                          <TableRow className="text-center bg-gray-200">
                            <TableCell
                              className="font-medium text-black py-2"
                              align="center"
                            >
                              Select
                            </TableCell>
                            <TableCell
                              className="font-medium text-black py-2"
                              align="center"
                            >
                              Student No.
                            </TableCell>
                            <TableCell
                              className="font-medium text-black p-2"
                              align="center"
                            >
                              Name
                            </TableCell>
                            <TableCell
                              className="font-medium text-black p-2"
                              align="center"
                            >
                              Category
                            </TableCell>
                            <TableCell
                              className="font-medium text-black p-2"
                              align="center"
                            >
                              Gender
                            </TableCell>
                            <TableCell
                              className="font-medium text-black p-2"
                              align="center"
                            >
                              DOB
                            </TableCell>
                            <TableCell
                              className="font-medium text-black p-2"
                              align="center"
                            >
                              Student Status
                            </TableCell>
                            <TableCell
                              className="font-medium text-black p-2"
                              align="center"
                            >
                              Show Profile
                            </TableCell>
                            <TableCell
                              className="font-medium text-black p-2"
                              align="center"
                            >
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredData
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((data, index) => (
                              <TableRow key={data._id}>
                                <TableCell align="center">
                                  <input
                                    type="checkbox"
                                    checked={selectedStudents.includes(data)}
                                    onChange={() => handleSelect(data)}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  {data.personalDetails.SID}
                                </TableCell>
                                <TableCell align="center">
                                  {data.personalDetails.FirstName}{" "}
                                  {data.personalDetails.LastName}
                                </TableCell>
                                <TableCell align="center">
                                  {data.personalDetails.Category}
                                </TableCell>
                                <TableCell align="center">
                                  {data.personalDetails.Gender}
                                </TableCell>
                                <TableCell align="center">
                                  {data.personalDetails.DOB?.slice(0, 10)}
                                </TableCell>
                                <TableCell align="center">
                                  {data?.studentmastersDetails?.StudentStatus}
                                </TableCell>
                                <TableCell align="center">
                                  <Link
                                    to={`/dashboard/verifyStudent/profile/${data?.studentmastersDetails?.SID}`}
                                  >
                                    <button
                                      type="submit"
                                      className="font-medium text-blue-600 hover:underline cursor-pointer"
                                    >
                                      Show Profile
                                    </button>
                                  </Link>
                                </TableCell>
                                <TableCell align="center">
                                  <button
                                    className="font-medium text-blue-600 hover:underline cursor-pointer"
                                    onClick={() =>
                                      handleDownloadSingleForm(data)
                                    }
                                  >
                                    {loadingStates[data._id] ? (
                                      <CircularProgress size={20} />
                                    ) : (
                                      "Download Form"
                                    )}
                                  </button>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className="flex justify-end mt-3">
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </div>

                    {/* Bulk Download Button */}
                    <div className="flex justify-center mt-3">
                      <button
                        className="bg-blue-700 text-white p-4 rounded-xl"
                        onClick={handleDownloadSelectedForms}
                      >
                        {selectedStudents.some(
                          (student) => loadingStates[student._id]
                        ) ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          "Download Selected Forms"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </MainCard>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default ViewStudent;
