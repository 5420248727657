// assets
import { IconDashboard } from "@tabler/icons";

import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ArticleIcon from "@mui/icons-material/Article";

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "dashboard",
  // title: 'Dashboard',
  type: "group",
  children: [
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: DashboardRoundedIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
